<template>
  <v-container
    class="d-flex flex-column justify-start"
    fluid
  >
    <v-row>
      <v-col class="pr-0 pl-3">
        <RunEditor
          v-if="selectedItem"
          :milestones="milestones"
          :item="selectedItem"
          @update-filter="updateItem"
          @update-tags="updateTags"
          @update-milestones="updateMilestones"
        />
        <CaseView
          :case-type="'EditCase'"
          :run-cases="runCases"
          :form="selectedItem"
          :tags="selectedTags"
          :milestones="selectedMilestones"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RunEditor from '@/components/TestRuns/RunEditor.vue';
import CaseView from '@/views/Tests/Runs/Create/RunAddCaseView';
import { mapGetters, mapState ,createNamespacedHelpers} from 'vuex';
import makeMilestoneService from '@/services/api/milestone'
const { mapState: mapProjectState, mapMutations, mapActions } = createNamespacedHelpers('run');
import { showSuccessToast,showAlertToast, showErrorToast } from '@/utils/toast';
export default {
  name: 'TestRunEdit',
  components: {
    RunEditor,
    CaseView,
  },
  props: ['customItem'],
  data() {
    return {
      selectedItem: null,
      runCases: [],
      milestones: [],
      loading: false,
      selectedTags: [],
      selectedMilestones: [],
    };
  },
  computed: {
    ...mapState('run', ['items']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
    }),
  },
  async created() {
    const handle = this.$route.params.handle;
    const projectKey = this.$route.params.key;
    const runId = this.$route.params.id;
    await this.getProjectRun(     
      handle,
      projectKey,
      runId
    );
  },
  methods: {
    ...mapActions(['getTestRunById']),
    updateItem(item){
      this.selectedItem = item
    },
    updateTags(tags){
      this.selectedTags = tags
    },
    updateMilestones(milestones){
      this.selectedMilestones = milestones
    },
    async getProjectRun(handle, projectKey, runId) {
      await this.getTestRunById({ handle, projectKey ,runId}).then( response => {
          this.selectedItem = response.data.run[0]
          this.runCases = response.data.runCases
        }).catch(() => {
          showErrorToast(this.$swal, this.$t('testruns.edit_testrun.not_found'))
          this.$router.push({name: 'Runs'})
        })
      
    },
    async getMilestones() {
      this.loading = true;
      try {
        const milestoneSrvice = makeMilestoneService(this.$api);
        const response = await milestoneSrvice.getMilestones(this.$route.params.handle, this.$route.params.key);
        this.milestones = response.data.milestones.map(milestone => ({name: milestone.name, uid: milestone.uid}));
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        this.milestones = []; // Reset to empty array in case of error
      } finally {
        this.loading = false;
      }
    },
    // Refreshes all data
    async refreshData() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const runId = this.$route.params.id;
      this.getProjectRun(
        handle,
        projectKey,
        runId
      );
    },
  },
  async mounted(){
    await this.getMilestones()
  }
};
</script>